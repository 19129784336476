import { gql } from "@apollo/client";
import { getOrigin } from "@utils/browser";
import { getHostShortMeetingLink } from "@utils/roomUrls";
import { darkButtonStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Button, Container, Icon, Link } from "components/shared";
import { SessionStudentEvaluationTable } from "components/shared/AttendanceGrades";
import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { useEffect, useMemo, useRef, useState } from "react";
import { EventTimeDetails } from "sections/UserDashboard/components/EventTimeDetails";
import { EventScheduleStatus } from "types/events";
import { EventDetails } from "../types";
import { getAttendedCount, getStudentEvaluationDetails } from "../utils";
import { CohortEventInfo } from "./CohortEventInfo";
import { SessionReportModal } from "./SessionReportModal/SessionReportModal";
import { getSessionButtonStyles } from "./SessionReportModal/utils";

TutorTabCohortEventPanel.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEvent on TutorDashboardEvent {
      subject
      cacheKey
      cohortId
      subSubject
      endDateTime
      engagementId
      startDateTime
      durationMinutes
      cohortSession {
        id
        sessionReportId
        sessionReportStudentPerformanceCount
      }
      ...SessionStudentEvaluationTable_TutorDashboardEvent
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardEvent}
  `,
  tutorDashboardCohort: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardCohort on TutorDashboardCohort {
      id
      name
      instructionLevel
      publicReferenceId
      activeStudentsCount
      ...SessionStudentEvaluationTable_TutorDashboardCohort
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardCohort}
  `,
  tutorDashboardEngagement: gql`
    fragment TutoringTabCohortEventPanel_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      name
      productType
      videoProvider
      ...SessionStudentEvaluationTable_TutorDashboardEngagement
    }
    ${SessionStudentEvaluationTable.fragments.tutorDashboardEngagement}
  `,
};

type Props = { eventDetails: EventDetails };

export function TutorTabCohortEventPanel({ eventDetails }: Props) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSessionOver, setIsSessionOver] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const { engagement, cohort, cohortSessionId, cohortSession, endDateTime } =
    eventDetails;

  const { studentEvaluationRows, evaluationDetails } = useMemo(
    () => getStudentEvaluationDetails(eventDetails),
    [eventDetails]
  );

  const { studentAttendanceEntries } = cohortSession || {};
  const { sessionReportId, sessionReportStudentPerformanceCount } =
    cohortSession ?? {};

  useEffect(() => {
    const checkIfSessionOver = () => {
      const now = Date.now();
      if (endDateTime <= now) return setIsSessionOver(true);
      const timeUntilEnd = endDateTime - now;
      timeoutRef.current = setTimeout(() => {
        setIsSessionOver(true);
      }, timeUntilEnd + 1);
    };

    checkIfSessionOver();

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [endDateTime]);

  const reportPerformanceComplete = useMemo(() => {
    const attendedCount = getAttendedCount(studentAttendanceEntries ?? []);
    const sessionCount = sessionReportStudentPerformanceCount ?? 0;
    return attendedCount <= sessionCount;
  }, [sessionReportStudentPerformanceCount, studentAttendanceEntries]);

  if (!engagement || !cohort) return null;

  const cohortHasActiveStudents = cohort.activeStudentsCount > 0;
  const hasAttendanceRecords = (studentAttendanceEntries ?? []).length > 0;
  const reportComplete = !!sessionReportId && reportPerformanceComplete;

  return (
    <Container
      key={eventDetails.cacheKey}
      className="flex flex-col gap-2 w-full overflow-visible z-auto"
    >
      <EventTimeDetails
        endTime={eventDetails.endDateTime}
        status={eventDetails.scheduleStatus}
        startTime={eventDetails.startDateTime}
        minutesElapsed={eventDetails.minutesElapsed}
        conclusion="This cohort session is now over."
        durationMinutes={eventDetails.durationMinutes}
        minutesRemaining={eventDetails.minutesRemaining}
      />
      <div
        className={clsx(
          "flex flex-col lg:flex-row lg:justify-between lg:items-center",
          "relative overflow-visible gap-2"
        )}
      >
        <CohortEventInfo
          subject={eventDetails.subject}
          cohortId={eventDetails.cohort?.id}
          subSubject={eventDetails.subSubject}
          cohortName={eventDetails.cohort?.name}
          engagementId={eventDetails.engagement?.id}
          engagementName={eventDetails.engagement?.name}
          productType={eventDetails.engagement?.productType}
          instructionLevel={eventDetails.cohort?.instructionLevel}
        />

        <div className="flex items-center gap-x-2 mb-1 w-full lg:w-fit justify-end">
          {cohortHasActiveStudents && hasAttendanceRecords && isSessionOver && (
            <Button
              height="xs"
              className={clsx(
                "leading-3 gap-x-2",
                darkButtonStyles,
                getSessionButtonStyles(reportComplete)
              )}
              onClick={() => setShowSessionModal(true)}
            >
              <Icon
                size={4}
                className="-ml-1"
                color="text-white"
                icon={reportComplete ? "eyeOn" : "submit"}
              />
              Session Report
            </Button>
          )}
          <Link
            target="_blank"
            className="h-30"
            href={getHostShortMeetingLink(
              getOrigin(),
              cohort.publicReferenceId,
              engagement.videoProvider
            )}
          >
            <Button height="xs" className="leading-3 px-6" theme="primary">
              Join Meeting
            </Button>
          </Link>
        </div>
      </div>

      <SessionReportModal
        cohortSessionId={String(cohortSessionId)}
        show={showSessionModal && !!cohortSessionId}
        sessionReportId={sessionReportId ?? null}
        onClose={() => setShowSessionModal(false)}
      />

      {cohortHasActiveStudents ? (
        <SessionStudentEvaluationTable
          evaluationDetails={evaluationDetails}
          studentEvaluationRows={studentEvaluationRows}
        />
      ) : (
        <EmptyStateContainer
          title={
            eventDetails.scheduleStatus === EventScheduleStatus.UPCOMING
              ? "This session hasn't occurred yet."
              : eventDetails.scheduleStatus === EventScheduleStatus.ONGOING
              ? "This session is in progress."
              : "This session has concluded."
          }
          showIcon={false}
          className="gap-1 py-3 px-5"
          subtitle={<p>No students have been assigned to this session.</p>}
        />
      )}
    </Container>
  );
}
