import {
  AccountRole,
  Cohort,
  Engagement,
  TutoringPreferencesPosition,
  User,
} from "@generated/graphql";
import { getAccountRoleText } from "@utils/labels";
import {
  AddInfractionModal,
  AttendanceScorecardModal,
  Icon,
  LoadingText,
  Tooltip,
} from "components/shared";
import { useState } from "react";

type Props = {
  id: User["id"];
  loading?: boolean;
  isInactive: boolean;
  email: User["email"];
  actions?: React.ReactNode;
  cohortId?: Cohort["id"];
  engagementId: Engagement["id"];
  fullName: User["fullName"];
};

export const StaffAssignmentAvatar = ({
  id,
  email,
  loading,
  fullName,
  isInactive,
  cohortId,
  engagementId,
  actions = null,
}: Props) => {
  const accountRole = cohortId
    ? AccountRole.TutorTeacher
    : TutoringPreferencesPosition.InstructionalSupport;
  const [openAttendanceScorecard, setOpenAttendanceScorecard] = useState(false);
  const [openInfractionModal, setOpenInfractionModal] = useState(false);

  return (
    <>
      <div className="flex flex-col rounded flex-1 group">
        <div className="ml-3 flex flex-col">
          {loading ? (
            <LoadingText />
          ) : (
            <>
              <div className="flex gap-2 items-center cursor-pointer">
                <span className="text-sm font-medium text-gray-900 whitespace-wrap">
                  {fullName} {getAccountRoleText(accountRole, "tiny")}
                </span>
              </div>

              <span className="text-sm text-gray-500">{email}</span>

              {isInactive && (
                <span className="text-sm text-red-500 font-medium">
                  Inactive Account
                </span>
              )}

              {actions}
            </>
          )}
        </div>
        <div className="flex gap-2 px-4 py-2">
          <Tooltip content="View Teacher Scorecard">
            <div
              className="h-9 w-9 flex flex-center cursor-pointer rounded-full bg-blue-700"
              onClick={() => !loading && setOpenAttendanceScorecard(true)}
            >
              <Icon icon="source" color="text-white" />
            </div>
          </Tooltip>
          <Tooltip content="Document Teacher Infraction">
            <div
              className="h-9 w-9 flex flex-center cursor-pointer rounded-full bg-red-500"
              onClick={() => !loading && setOpenInfractionModal(true)}
            >
              <Icon icon="infraction" color="text-white" />
            </div>
          </Tooltip>
        </div>
      </div>

      <AttendanceScorecardModal
        userId={id}
        show={openAttendanceScorecard}
        closeModal={() => {
          setOpenAttendanceScorecard(false);
        }}
      />

      {openInfractionModal && (
        <AddInfractionModal
          teacherId={id}
          teacherName={fullName}
          cohortId={cohortId}
          engagementId={engagementId}
          show={openInfractionModal}
          closeModal={() => {
            setOpenInfractionModal(false);
          }}
        />
      )}
    </>
  );
};
